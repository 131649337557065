<template>
    <crud-index-component v-bind="$data">
        <template slot="header">
            {{ $t('crud.transporter_zones._plural') }}
        </template>
    </crud-index-component>
</template>

<script>
    import CrudIndexComponent from "../crud/Index";
    import {config} from '../crud/mixin_config';

    export default {
        components: {CrudIndexComponent},
        data() {
            return {
                crud: config('transporter_zones'),
                fields: [{
                    title: this.$t('crud.id'),
                    name: 'id',
                    sortField: 'id'
                }, {
                    title: this.$t('crud.name'),
                    name: 'name',
                    sortField: 'name'
                }, {
                    title: this.$t('crud.transporters._singular'),
                    name: 'transporter',
                    sortField: 'transporter',
                    formatter: (transporter) => {
                        if(transporter) {
                            return '<img style="max-height: 20px" src="' + transporter.logo + '" alt="' + transporter.name + '"/>';
                        }
                    }
                },{
                    title: this.$t('crud.transporter_zones.max_pallets_per_day'),
                    name: 'max_pallets_per_day',
                    sortField: 'max_pallets_per_day'
                },{
                    title: this.$t('crud.actions'),
                    name: 'slot-actions'
                }]
            };
        },
        methods: {}
    }
</script>
